<template>
  <tr v-if="role">
    <td>{{ displayName }}</td>
    <td
      v-for="(name, i) in columns"
      :key="i"
      :class="{'disabled-cell': !perms.includes(name)}"
      class="center"
    >
      <input
        v-model="role[permName(name)]"
        type="checkbox"
        :disabled="!perms.includes(name)"
        :name="permName(name)"
      >
    </td>
    <td>
      <input
        ref="selectAll"
        type="checkbox"
        class="select-all"
        @click="selectAll()"
      >
    </td>
  </tr>
</template>

<script>
export default {
  props: [
    'role',
    'group',
    'columns'
  ],
  data () {
    return {
      defaultPerms: ['read', 'write', 'delete']
    }
  },
  computed: {
    displayName () {
      return this.group.display_name || this.group.name
    },
    perms () {
      if (this.group.permissions) {
        return this.group.permissions
      } else {
        return this.defaultPerms
      }
    }
  },
  methods: {
    columnName () {
      return this.group.name.toLowerCase().replace(/ /g, '_')
    },
    permName (perm) {
      return 'can_' + perm + '_' + this.columnName()
    },
    selectAll () {
      for (var i in this.columns) {
        if (this.perms.includes(this.columns[i])) {
          this.role[this.permName(this.columns[i])] = this.$refs.selectAll.checked
        }
      }
      this.$forceUpdate() // Yay vue arrays!
    }
  }
}
</script>
