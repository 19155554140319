<template>
  <div>
    <top-nav />
    <themes-index v-if="this.$route.name == 'themes'" />
    <themes-edit v-if="this.$route.name == 'theme'" />
    <roles-index v-if="this.$route.name == 'roles'" />
    <roles-edit v-if="this.$route.name == 'role'" />
    <tags-index v-if="this.$route.name == 'tags'" />
    <products-index v-if="this.$route.name == 'products'" />
    <content-sources-index v-if="this.$route.name == 'content-sources'" />
  </div>
</template>

<script>
import ThemesIndex from '../themes/Index'
import ThemesEdit from '../themes/Edit.vue'
import RolesIndex from '../roles/Index.vue'
import RolesEdit from '../roles/Edit.vue'
import TagsIndex from '../tags/Index.vue'
import ContentSourcesIndex from '../content_sources/Index.vue'
import TopNav from '../shared/TopNav.vue'
import ProductsIndex from '../products/Index.vue'

export default {
  components: {
    ThemesIndex,
    ThemesEdit,
    RolesIndex,
    RolesEdit,
    TagsIndex,
    TopNav,
    ProductsIndex,
    ContentSourcesIndex
  }
}
</script>
