<template>
  <div class="settings-roles-section">
    <div class="inner-content">
      <div class="inner-top-bar sticky-top-bar">
        <router-link
          class="back-arrow"
          :to="{name: 'roles'}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <path
                fill="#667680"
                fill-rule="nonzero"
                d="M13.794 20.143h15.095C29.503 20.143 30 19.63 30 19s-.497-1.143-1.111-1.143H13.794l4.77-4.906a1.166 1.166 0 0 0 0-1.616 1.09 1.09 0 0 0-1.572 0l-6.667 6.857a1.166 1.166 0 0 0 0 1.616l6.667 6.857a1.09 1.09 0 0 0 1.571 0 1.166 1.166 0 0 0 0-1.616l-4.77-4.906z"
              />
              <circle
                cx="19"
                cy="19"
                r="18"
                stroke="#667680"
                stroke-width="2"
              />
            </g>
          </svg>
        </router-link>
        <h1>{{ role.name }}</h1>
        <button
          class="hlt-button warning delete-role-button"
          @click="showDeleteModal()"
        >
          Delete
        </button>
        <button
          class="hlt-button primary save-role-button"
          @click="save()"
        >
          Save Role
        </button>
      </div>

      <modal
        name="delete-modal"
        height="auto"
        :scrollable="false"
        class="vue-modal"
        width="45%"
      >
        <div
          v-if="role.id != undefined"
          class="vue-modal-inner"
        >
          <div class="vue-modal-topbar">
            <h4>Are you sure you want to delete this Role?</h4>
          </div>
          <div class="modal-container">
            <div>{{ role.name }}</div>
            <div>
              {{ role.user_count }} users have this Role.
            </div>
          </div>

          <div class="vue-modal-bottombar modal-buttons">
            <button
              class="hlt-button warning confirm-delete modal-button"
              @click="destroy()"
            >
              Delete
            </button>
            <button
              class="hlt-button primary cancel-button modal-button"
              @click="cancel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>

      <div class="edit-content-container">
        <label>Name <abbr title="required">*</abbr></label>
        <input
          v-model="role.name"
          type="text"
          name="name"
        >

        <label>Description</label>
        <textarea
          v-model="role.description"
          rows="5"
          name="description"
        />

        <label>Assets</label>
        <div class="table-container">
          <table class="roles-table">
            <thead>
              <tr>
                <th>Permission</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
                <th class="search-by-user">
                  Search by User
                </th>
                <th class="overwrite-live">
                  Overwrite Live
                </th>
                <th>Select All</th>
              </tr>
            </thead>
            <tbody>
              <group
                v-for="(group, index) in assetPermissions"
                :id="'group' + index"
                :key="'assets' + index"
                :group="group"
                :role="role"
                :columns="assetColumns"
              />
            </tbody>
          </table>
        </div>

        <label>App Manager</label>
        <div class="table-container">
          <table class="roles-table">
            <thead>
              <tr>
                <th>Permission</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
                <th class="search-by-user">
                  Search by User
                </th>
                <th class="overwrite-live">
                  Overwrite Live
                </th>
                <th>Select All</th>
              </tr>
            </thead>
            <tbody>
              <group
                v-for="(group, index) in appManager"
                :id="'group' + index"
                :key="'other' + index"
                :group="group"
                :role="role"
                :columns="appManagerColumns"
              />
            </tbody>
          </table>
        </div>

        <label>Asset Assignment Workflow</label>
        <div
          v-if="role != {}"
          class="table-container"
        >
          <table class="roles-table">
            <thead>
              <tr>
                <th>Permission</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Manager</td>
                <td>
                  <input
                    v-model="role.is_a_asset_assigner"
                    type="checkbox"
                    name="is_a_asset_assigner"
                  >
                </td>
              </tr>
              <tr>
                <td>Copy Writer</td>
                <td>
                  <input
                    v-model="role.is_a_copy_writer"
                    type="checkbox"
                    name="is_a_copy_writer"
                  >
                </td>
              </tr>
              <tr>
                <td>Copy Editor</td>
                <td>
                  <input
                    v-model="role.is_a_copy_editor"
                    type="checkbox"
                    name="is_a_copy_editor"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <label>Other</label>
        <div class="table-container">
          <table class="roles-table">
            <thead>
              <tr>
                <th>Permission</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Publish</th>
                <th>Select All</th>
              </tr>
            </thead>
            <tbody>
              <group
                v-for="(group, index) in permissionGroups"
                :id="'group' + index"
                :key="'other' + index"
                :group="group"
                :role="role"
                :columns="otherColumns"
              />
            </tbody>
          </table>
        </div>

        <label>Workspace Widgets</label>
        <div
          v-if="role != {}"
          class="table-container"
        >
          <table class="roles-table">
            <thead>
              <tr>
                <th>Widget</th>
                <th>Visible</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Recent Activity</td>
                <td>
                  <input
                    v-model="role.can_read_cms_recent_activity"
                    type="checkbox"
                    name="can_read_cms_recent_activity"
                  >
                </td>
              </tr>
              <tr>
                <td>Low PB Values</td>
                <td>
                  <input
                    v-model="role.can_read_cms_low_pb_values"
                    type="checkbox"
                    name="can_read_cms_low_pb_values"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import railsRoutes from '../../../store/rails-routes'
import Group from './Group.vue'
import axios from 'axios'
export default {
  components: { Group },
  data () {
    return {
      assetColumns: ['read', 'write', 'delete', 'search_by_user_on', 'overwrite_live'],
      appManagerColumns: [
        'read',
        'write',
        'delete',
        'search_by_user_on',
        'overwrite_live'
      ],
      otherColumns: [
        'read',
        'write',
        'delete',
        'publish'
      ],
      assetPermissions: [
        {
          name: 'Attachments',
          permissions: [
            'read',
            'write',
            'delete',
            'search_by_user_on'
          ]
        },
        { name: 'Attachments Product Associations', permissions: ['read', 'write', 'delete'] },
        { name: 'Flashcards', display_name: 'Items', permissions: ['read', 'write', 'delete', 'search_by_user_on'] },
        { name: 'Items Product Associations', permissions: ['read', 'write', 'delete'] },
        { name: 'Groupings', permissions: ['read', 'write', 'delete', 'search_by_user_on', 'overwrite_live'] },
        { name: 'Groupings Product Associations', permissions: ['read', 'write', 'delete'] },
        { name: 'Learning Modules', permissions: ['read', 'write', 'delete', 'search_by_user_on', 'overwrite_live'] },
        { name: 'Learning Modules Product Associations', permissions: ['read', 'write', 'delete'] },
        { name: 'Topics', permissions: ['read', 'write', 'delete', 'search_by_user_on', 'overwrite_live'] },
        { name: 'Topics Product Associations', permissions: ['read', 'write', 'delete'] }
      ],
      appManager: [
        { name: 'App Hierarchy', permissions: ['read'] },
        { name: 'App Dashboards', permissions: ['read', 'write', 'delete'] },
        { name: 'Catalogs', permissions: ['read', 'write', 'delete', 'overwrite_live'] },
        { name: 'Catalog Pages', permissions: ['read', 'write', 'delete', 'overwrite_live'] },
        { name: 'Dashboard Widgets', permissions: ['read', 'write', 'delete'] },
        { name: 'Decks', permissions: ['read', 'write', 'delete', 'search_by_user_on', 'overwrite_live'] },
        { name: 'Decks Product Associations', permissions: ['read', 'write', 'delete'] }
      ],
      permissionGroups: [
        { name: 'ActivatorsApps' },
        { name: 'Admin Users' },
        { name: 'Annotations' },
        { name: 'Answer Statuses' },
        { name: 'Apps' },
        { name: 'Cases' },
        { name: 'Categories', permissions: ['read', 'write', 'delete', 'publish'] },
        { name: 'Category Statuses' },
        { name: 'Category Types' },
        { name: 'Content Sources' },
        { name: 'Coupons' },
        { name: 'Courses' },
        { name: 'Course Schedules' },
        { name: 'Discussions' },
        { name: 'Exam Strategies' },
        { name: 'Exam Strategy Types' },
        { name: 'Exhibits' },
        { name: 'Field Of Studies' },
        { name: 'Flashcard Statuses' },
        { name: 'Flipcards' },
        { name: 'Images' },
        { name: 'Institutions' },
        { name: 'Instructors' },
        { name: 'IP User Mappings' },
        { name: 'Landing Pages' },
        { name: 'License Key Requests' },
        { name: 'Menus' },
        { name: 'Mnemonic Types' },
        { name: 'Mnemonics' },
        { name: 'Navigation Groups' },
        { name: 'Navigation Icons' },
        { name: 'Navigation Items' },
        { name: 'New CMS', permissions: ['read'] },
        { name: 'Partners' },
        { name: 'Product Images' },
        { name: 'Product Image Types' },
        { name: 'Product Vars' },
        { name: 'Product Var Types' },
        { name: 'Purchase Order Bundles' },
        { name: 'Purchase Orders' },
        { name: 'Purchase Receipts' },
        { name: 'Roles' },
        { name: 'Study Strategies' },
        { name: 'Simulation Configs' },
        { name: 'Tags' },
        { name: 'Themes' },
        { name: 'User Import Batches', permissions: ['read', 'write'] },
        { name: 'Users' },
        { name: 'User Sources' },
        { name: 'User Types' },
        { name: 'Web App Configs' },
        { name: 'Web App SEO Configs' },
        { name: 'Web Dashboard Configs' },
        { name: 'Web Pages' }
      ],
      role: {}
    }
  },
  created () {
    axios.get(railsRoutes.api_path({ type: 'roles', id: this.$route.params.id })).then(response => {
      this.role = response.data.records[0]
    })
  },
  methods: {
    save () {
      axios.put(railsRoutes.api_path({ type: 'roles', id: this.$route.params.id }), { role: this.role }).then(response => {
        this.role = response.data.records[0]
        this.$notify({ title: 'Role Saved', type: 'success' })
      }, error => {
        console.log(error)
        this.$notify({ title: 'Error saving role', type: 'error' })
      })
    },
    showDeleteModal () {
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    cancel () {
      if (this.$modal) {
        this.$modal.hide('delete-modal')
      }
    },
    destroy () {
      axios.delete(railsRoutes.api_path({ type: 'roles', id: this.$route.params.id })).then(response => {
        this.$notify({ title: 'Role Deleted', type: 'success' })
        if (this.$router) {
          this.$router.push({ path: railsRoutes.cms_path({ type: 'roles' }) })
        }
      }, error => {
        console.log(error)
        this.$notify({ title: 'Error deleting role', type: 'error' })
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.settings-roles-section {
  .inner-content {
    position: relative;
  }
  .modal-buttons {
    button {
      margin-left: 10px;
    }
  }
  .save-role-button {
    margin-left: 10px;
  }
}
</style>
