<template>
  <div>
    <search />
    <edit-modal />
  </div>
</template>

<script>
import Search from '../../shared/Search'
import EditModal from './Edit'
export default {
  components: {
    Search,
    EditModal
  }
}
</script>
