<template>
  <div
    v-if="themes"
    class="inner-content"
  >
    <div class="inner-top-bar sticky-top-bar">
      <h1>Themes</h1>
    </div>
    <div class="row small-up-1 medium-up-2 large-up-3 grid-row">
      <div
        v-for="theme in themes"
        :id="'theme' + theme.id"
        :key="theme.id"
        class="column grid-column"
        :theme="theme"
      >
        <div class="grid-column-inner">
          <div class="grid-heading">
            {{ theme.name }}
          </div>
          <div class="grid-text">
            {{ theme.name }}
          </div>
          <div class="grid-buttons">
            <a
              href="#"
              class="edit-button"
              @click="copyTheme(theme.id)"
            >Copy</a>
            <router-link
              :to="&quot;/themes/&quot; + theme.id + &quot;/edit&quot;"
              class="edit-button"
            >
              Edit
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  methods: {
    copyTheme (id) {
      this.copyTheme().then(() => {
        this.$notify({ title: "Theme Copied", type: "success"})
      }, error => {
        this.$notify({ title: "Error copying theme", type: "error"})
      })
    },
    ...mapActions(['getThemes', 'copyTheme']),
    ...mapMutations([
      'setAssetType'
    ])
  },
  created () {
    this.getThemes()
    this.setAssetType({ assetType: 'role' })
  },
  computed: {
    ...mapGetters(['themes'])
  }
}
</script>
<style lang="scss" scoped>
.inner-content {
  position: relative;
}
</style>
