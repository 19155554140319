<template>
  <div
    v-if="roles"
    class="inner-content"
  >
    <div class="inner-top-bar sticky-top-bar">
      <h1>Roles</h1>
    </div>
    <div class="row small-up-1 medium-up-2 large-up-3 grid-row">
      <div
        v-for="role in roles"
        :id="'role' + role.id"
        :key="role.id"
        class="column grid-column"
        :role="role"
      >
        <div class="grid-column-inner">
          <div class="grid-heading">
            {{ role.name }}
          </div>
          <div class="grid-text">
            {{ role.description }}
          </div>
          <div class="grid-buttons">
            <router-link
              :to="&quot;/roles/&quot; + role.id + &quot;/edit&quot;"
              class="edit-button"
            >
              Edit
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters(['roles'])
  },
  created () {
    this.getRoles()
    this.setAssetType({ assetType: 'role' })
  },
  methods: {
    ...mapActions(['getRoles']),
    ...mapMutations([
      'setAssetType'
    ])
  }
}
</script>
<style lang="scss" scoped>
.inner-content {
  position: relative;
}
</style>
