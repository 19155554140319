<template>
  <div
    class="inner-content"
  >
    <div class="inner-top-bar sticky-top-bar">
      <h1>Products</h1>
    </div>
    <div class="products">
      <div
        v-for="app in availableApps"
        :id="'app' + app.id"
        :key="app.id"
        class="product"
      >
        <h3>
          {{ app.internal_name }}
        </h3>
        <div class="product-id">
          ID: {{ app.id }}
        </div>
        <div class="buttons">
          <button
            class="hlt-button default reset-cache-button"
            @click="resetWebAppCache(app.id)"
            v-if="currentUser.permissions.can_write_web_app_configs"
          >Reset Cache</button>
          <button
            class="hlt-button secondary"
            @click="edit(app.id)"
            v-if="currentUser.permissions.can_write_apps"
          >Configure</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import railsRoutes from '../../../store/rails-routes'

export default {
  computed: {
    ...mapGetters([
      'apps',
      'currentUser'
    ]),
    availableApps () {
      var userApps = this.currentUser.apps
      var userAppIds = this.currentUser.apps.map(x => x.id)
      return userApps.concat(this.apps
        .filter(k => userAppIds.indexOf(k.id) === -1)
        .filter(l => l.internal_name !== undefined))
        .sort((a, b) => {
          return a.id - b.id
        })
    }
  },
  methods: {
    edit (appId) {
      this.$router.push({ name: 'product', params: { id: appId }})
    },
    resetWebAppCache (appId) {
      axios.post(railsRoutes.api_path({ type: 'web_app_configs', action: 'reset_web_app_cache' }), { app_id: appId }).then(() => {
        this.$notify({ title: 'Reset web app cache request submitted', type: 'info' })
      }, error => {
        console.log(error)
        this.$notify({ title: 'Error saving resetting web cache', type: 'error' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.inner-content {
  .grid-text {
    margin: 0;
    height: initial;
  }
  .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $space-black-20;
    font-size: 1em;

    .product {
      width: 30%;
      margin: 10px;
      border: 1px solid $space-gray;
      border-radius: 5px;
      padding: 20px;
      h3 {
        margin-bottom: 0px;
        font-size: 1.2em;
        font-weight: 700;
        color: $space-black;
      }
      .product-id {
        font-weight: 700;
      }
      .buttons {
        display: flex;
        justify-content: right;
        button {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
