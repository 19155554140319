<template>
  <div class="themes-edit-section">
    <div class="inner-content">
      <div class="inner-top-bar sticky-top-bar">
        <router-link
          class="back-arrow"
          :to="{name: 'themes'}"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <path
                fill="#667680"
                fill-rule="nonzero"
                d="M13.794 20.143h15.095C29.503 20.143 30 19.63 30 19s-.497-1.143-1.111-1.143H13.794l4.77-4.906a1.166 1.166 0 0 0 0-1.616 1.09 1.09 0 0 0-1.572 0l-6.667 6.857a1.166 1.166 0 0 0 0 1.616l6.667 6.857a1.09 1.09 0 0 0 1.571 0 1.166 1.166 0 0 0 0-1.616l-4.77-4.906z"
              />
              <circle
                cx="19"
                cy="19"
                r="18"
                stroke="#667680"
                stroke-width="2"
              />
            </g>
          </svg>
        </router-link>
        <h1>{{ theme.name }}</h1>
        <button
          class="button hollow-red-button"
          @click="showDeleteModal()"
        >
          DELETE
        </button>
        <button
          class="button solid-green-button"
          @click="save()"
        >
          SAVE THEME
        </button>
      </div>

      <modal
        name="delete-modal"
        height="auto"
        :scrollable="false"
        class="vue-modal"
        width="45%"
      >
        <div
          v-if="theme.id != undefined"
          class="vue-modal-inner"
        >
          <div class="vue-modal-topbar">
            <h4>Are you sure you want to delete this Theme?</h4>
          </div>
          <div class="modal-container">
            <div>{{ theme.name }}</div>
            <div>
              {{ appsCount }} apps use this Theme.
            </div>
          </div>

          <div class="vue-modal-bottombar modal-buttons">
            <button
              class="button solid-red-button modal-button"
              @click="destroy()"
            >
              Delete
            </button>
            <button
              class="button solid-green-button modal-button"
              @click="cancel()"
            >
              Cancel
            </button>
          </div>
        </div>
      </modal>

      <div class="edit-content-container">
        <label>Name <abbr title="required">*</abbr></label>
        <input
          v-model="theme.name"
          type="text"
          name="name"
        >

        <label>Content CSS</label>
        <textarea
          v-model="theme.content_css"
          rows="20"
          name="content_css"
        />

        <div
          id="themes_table_container"
          class="table-container"
        >
          <table
            id="themes_table"
            class="asset-table"
          >
            <thead>
              <tr>
                <th>Key</th>
                <th>Red</th>
                <th>Green</th>
                <th>Blue</th>
                <th>Alpha</th>
              </tr>
            </thead>
            <tbody id="theme_colors">
              <tr
                v-for="themeColorKey in themeColorKeys"
                :id="'themeColorKey' + themeColorKey"
                :key="themeColorKey"
                class="nested-fields"
                :themeColorKey="themeColorKey"
              >
                <td>{{ themeColorKey }}</td>
                <td>
                  <input
                    v-model="getThemeColor(themeColorKey).r"
                    min="0"
                    max="255"
                    type="number"
                  >
                </td>
                <td>
                  <input
                    v-model="getThemeColor(themeColorKey).g"
                    min="0"
                    max="255"
                    type="number"
                  >
                </td>
                <td>
                  <input
                    v-model="getThemeColor(themeColorKey).b"
                    min="0"
                    max="255"
                    type="number"
                  >
                </td>
                <td>
                  <input
                    v-model="getThemeColor(themeColorKey).a"
                    min="0"
                    max="1"
                    step=".01"
                    type="number"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import railsRoutes from '../../../store/rails-routes'

export default {
  data () {
    return {
      theme: {},
      themeColorKeys: [],
      appsCount: 0
    }
  },
  created () {
    axios.get(railsRoutes.api_path({ type: 'themes', id: this.$route.params.id })).then(response => {
      this.theme = response.data.records[0]
      this.themeColorKeys = response.data.meta.theme_color_keys
    })
  },
  methods: {
    getThemeColor (key) {
      var color = this.theme.theme_colors.find(o => o.key === key)
      if (color) {
        return color
      } else {
        this.theme.theme_colors.push({ key: key, r: '0', g: '0', b: '0', a: '0.0' })
        return {}
      }
    },
    save () {
      axios.put(railsRoutes.api_path({ type: 'themes', id: this.$route.params.id }), { theme: this.theme }).then(response => {
        this.theme = response.data.records[0]
        this.$notify({ title: 'Theme Saved', type: 'success' })
      }, error => {
        console.log(error)
        this.$notify({ title: 'Error saving theme', type: 'error' })
      })
    },
    showDeleteModal () {
      if (this.$modal) {
        this.$modal.show('delete-modal')
      }
    },
    cancel () {
      if (this.$modal) {
        this.$modal.hide('delete-modal')
      }
    },
    destroy () {
      axios.delete(railsRoutes.api_path({ type: 'themes', id: this.$route.params.id })).then(response => {
        this.$notify({ title: 'Theme Deleted', type: 'success' })
        if (this.$router) {
          this.$router.push({ path: railsRoutes.cms_path({ type: 'themes' }) })
        }
      }, error => {
        console.log(error)
        this.$notify({ title: 'Error deleting theme', type: 'error' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#themes_table {
  input[type="number"] {
    border: 2px solid $grey-3;
    border-radius: 3px;
    margin: 0;
  }

  td {
    padding: 16px;
  }
}
.themes-edit-section {
  .inner-content {
    position: relative;
  }
}
</style>
