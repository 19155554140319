<template>
  <modal
    v-if="asset"
    name="edit-tag-modal"
    height="auto"
    :scrollable="false"
    class="vue-modal"
    width="45%"
  >
    <div class="vue-modal-inner">
      <div class="vue-modal-topbar">
        <h4>Tag #{{ asset.id }}</h4>
      </div>
      <div class="modal-container">
        <div class="tag-container">
          <div class="tag-section">
            <label for="tag-name">Tag Name</label>
            <input
              v-model="asset.name"
              name="tag-name"
              type="text"
              class="hlt-input"
            >
            <error-message
              v-if="errors"
              :message="prettyErrors"
            />
          </div>
          <div class="tag-row">
            <div class="tag-section row-item">
              <label
                for="tag-type"
                class="tag-label"
              >Tag Type</label>
              <select
                v-model="asset.tag_type"
                name="tag-type"
                class="hlt-select"
              >
                <option value="Standard">
                  Standard
                </option>
                <option value="Content">
                  Content
                </option>
                <option value="Asset">
                  Asset
                </option>
              </select>
            </div>
            <div class="tag-section">
              <label for="tag-name">Vertical</label>
              <select
                v-if="verticals"
                v-model="asset.vertical_id"
                name="tag-vertical"
                class="hlt-select"
              >
                <option value="null">
                  Select Vertical
                </option>
                <option
                  v-for="vertical in verticals"
                  :key="vertical.id"
                  :value="vertical.id"
                >
                  {{ vertical.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="tag-row">
            <div class="tag-section">
              <label for="tag-name">Standard Set</label>
              <input
                v-model="asset.standard_set"
                name="tag-name"
                type="text"
                class="hlt-input"
              >
            </div>
          </div>
          <div class="tag-row">
            <div class="tag-section toggle-section">
              <hlt-toggle
                style-type="medium"
                :value="asset.visibility"
                :labels="{
                  checked: 'Visible', unchecked: 'Not Visible'
                }"
                sync
                @change="toggleVisibility"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vue-modal-bottombar modal-buttons">
      <button
        class="hlt-button primary save-tag-button"
        @click="saveTag(asset)"
      >
        Save
      </button>
      <button
        class="hlt-button default modal-button"
        @click="cancel"
      >
        Cancel
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes.js'
import HltToggle from '../../../components/assets/shared/page_components/Toggle'
import axios from 'axios'
export default {
  components: {
    HltToggle
  },
  data () {
    return {
      errors: null,
      verticals: null
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'assetType',
      'currentUser',
      'canWriteToAsset'
    ]),
    prettyErrors () {
      if (this.errors.name) {
        if (this.errors.name.indexOf('can\'t be blank') >= 0) {
          return 'Name can\'t be blank'
        }
        if (this.errors.name.indexOf('has already been taken') >= 0) {
          return 'Name has already been taken'
        }
      }
      return 'Error'
    }
  },
  created () {
    axios.get(railsRoutes.api_path({ type: 'verticals' })).then(response => {
      this.verticals = response.data.records
    })
  },
  methods: {
    toggleVisibility () {
      if (this.canWriteToAsset && this.currentUser.permissions.can_write_tags) {
        this.asset.visibility = !this.asset.visibility
      }
    },
    cancel () {
      this.$modal.hide('edit-tag-modal')
    },
    saveTag (asset) {
      this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'tags' }) }).then(() => {
        this.errors = null
        this.resetAsset()
        this.$notify({ title: 'Tag Saved', type: 'success' })
        if (this.$modal) {
          this.$modal.hide('edit-tag-modal')
        }
        this.resetSearchResults()
        this.searching()
      }).catch(error => {
        console.error(error)
        if (error && error.response) {
          this.errors = error.response.data
        }
        this.$notify({ title: 'Error Saving Tag', type: 'error' })
      })
    },
    ...mapActions([
      'update',
      'searching'
    ]),
    ...mapMutations([
      'resetSearchResults',
      'setAssetType',
      'setErrors',
      'resetErrors',
      'resetAsset'
    ])
  }

}
</script>

<style lang="scss" scoped>
.tag-container {
  padding: 0 1rem;
  .tag-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .tag-section {
      flex: 1;
      &.row-item {
        justify-content: space-between;
        margin-right: 15px;
      }
      &.toggle-section {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.modal-buttons {
  padding: 15px 30px;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  button {
    margin-left: 15px;
  }
}
</style>
